<template>
  <div class="mainWrapper" :class="{ isLoaded: isLoaded }">
    <preLoader @enterClick="handleEnterClick" :showButton="showButton" />
    <mainNav />
    <siteMusic />
    <div id="main">
      <div id="page">
        <bodyBgs />
        <modelAndHero />
      </div>
      <router-view />
      <div
        class="homeFooterSec"
        :class="{ darkMode: isHowWeWork }"
        v-if="isNotContact"
      >
        <div class="homeFooterTxtInfo">
          <h2 v-if="isNotService">
            You are Here Because The Whole World Conspired for You To
          </h2>
          <h2 v-else class="servicesPageFtTxt">
            Catalyze Your Online Growth Now.
          </h2>
          <router-link to="/contact-us" v-if="isNotService" class="homeFtLine">
            MEET US
          </router-link>
          <router-link to="/contact-us" v-else class="homeFtLine whiteText">
            Let's Chat
          </router-link>
        </div>
        <siteFooter />
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-if="routeLoadingScreen"
        key="overlay"
        class="changingScreeOverlay"
      ></div>
    </transition>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LocomotiveScroll from "locomotive-scroll";
import mainNav from "@/components/mainNav.vue";
import router from "./router";
import preLoader from "@/components/preLoader.vue";

import siteMusic from "@/components/siteMusic.vue";
import bodyBgs from "@/components/homePage/bodyBgArea.vue";
import modelAndHero from "@/components/homePage/modelAndHero.vue";
import siteFooter from "@/components/siteFooter.vue";
export default {
  name: "App",
  components: {
    siteMusic,
    mainNav,
    preLoader,
    bodyBgs,
    modelAndHero,
    siteFooter,
  },
  data() {
    return {
      isLoaded: false,
      showButton: false,
      routeLoadingScreen: false,
    };
  },
  watch: {
    $route() {
      // Set loading to true on route change
      this.routeLoadingScreen = true;

      // Simulate an asynchronous operation
      setTimeout(() => {
        // Set loading back to false after 1 second
        this.routeLoadingScreen = false;
      }, 1500);
    },
  },
  methods: {
    handleEnterClick() {
      this.isLoaded = true;
      // this.showButton = false;
    },
    particleAnim: function () {
      const particlesContainer = document.getElementById("particles-container");
      var particleCount = 600; //on desktop screen
      if (window.innerWidth < 768) {
        particleCount = 100; //on mobiles screen
      }

      const particles = [];

      // Create particles
      for (let i = 0; i < particleCount; i++) {
        createParticle();
      }

      function createParticle() {
        const particle = document.createElement("div");
        particle.className = "particle";

        const size = Math.random() * 3 + 3; // Random size between 2px and 4px
        const x = Math.random() * window.innerWidth;
        const y = Math.random() * window.innerHeight;
        const color = getRandomColor();

        particle.style.width = `${size}px`;
        particle.style.height = `${size}px`;
        particle.style.left = `${x}px`;
        particle.style.top = `${y}px`;
        particle.style.background = color;

        particlesContainer.appendChild(particle);

        // Store particles in the array
        particles.push({
          element: particle,
          x,
          y,
          speedY: Math.random() * 0.5 + 0.1, // Slower vertical speed
          speedX: Math.random() * 0.5 + 0.1, // Slower horizontal speed
        });
      }

      // Animation loop
      function animateParticles() {
        for (const particle of particles) {
          // Update particle positions for a parallax effect
          particle.x -= particle.speedX; // Added horizontal motion
          particle.y -= particle.speedY; // Added vertical motion

          // Reset particle position if it goes above the viewport
          if (particle.y < 0) {
            particle.y = window.innerHeight + Math.random() * 100;
            particle.x = Math.random() * window.innerWidth;
          }

          // Apply the new position
          particle.element.style.transform = `translate(${particle.x}px, ${particle.y}px)`;
        }

        requestAnimationFrame(animateParticles);
      }

      // Helper function to generate random colors
      function getRandomColor() {
        const colors = ["#ffffff", "#ebebeb", "#d7d7d7"]; // White, light gray, and dark gray
        return colors[Math.floor(Math.random() * colors.length)];
      }

      // Start the animation loop
      animateParticles();
    },
    homeBottleAnim: function () {
      const canvas = document.querySelector("canvas");
      const context = canvas.getContext("2d");

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      window.addEventListener("resize", function () {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        render();
      });

      function files(index) {
        var data = `
     ../model/_0001.png
     ../model/_0002.png
     ../model/_0003.png
     ../model/_0004.png
     ../model/_0005.png
     ../model/_0006.png
     ../model/_0007.png
     ../model/_0008.png
     ../model/_0009.png
     ../model/_0010.png
     ../model/_0011.png
     ../model/_0012.png
     ../model/_0013.png
     ../model/_0014.png
     ../model/_0015.png
     ../model/_0016.png
     ../model/_0017.png
     ../model/_0018.png
     ../model/_0019.png
     ../model/_0020.png
     ../model/_0021.png
     ../model/_0022.png
     ../model/_0023.png
     ../model/_0024.png
     ../model/_0025.png
     ../model/_0026.png
     ../model/_0027.png
     ../model/_0028.png
     ../model/_0029.png
     ../model/_0030.png
     ../model/_0031.png
     ../model/_0032.png
     ../model/_0033.png
     ../model/_0034.png
     ../model/_0035.png
     ../model/_0036.png
     ../model/_0037.png
     ../model/_0038.png
     ../model/_0039.png
     ../model/_0040.png
     ../model/_0041.png
     ../model/_0042.png
     ../model/_0043.png
     ../model/_0044.png
     ../model/_0045.png
     ../model/_0046.png
     ../model/_0047.png
     ../model/_0048.png
     ../model/_0049.png
     ../model/_0050.png
     ../model/_0051.png
     ../model/_0052.png
     ../model/_0053.png
     ../model/_0054.png
     ../model/_0055.png
     ../model/_0056.png
     ../model/_0057.png
     ../model/_0058.png
     ../model/_0059.png
     ../model/_0060.png
     ../model/_0061.png
     ../model/_0062.png
     ../model/_0063.png
     ../model/_0064.png
     ../model/_0065.png
     ../model/_0066.png
     ../model/_0067.png
     ../model/_0068.png
     ../model/_0069.png
     ../model/_0070.png
     ../model/_0071.png
     ../model/_0072.png
     ../model/_0073.png
     ../model/_0074.png
     ../model/_0075.png
     ../model/_0076.png
     ../model/_0077.png
     ../model/_0078.png
     ../model/_0079.png
     ../model/_0080.png
     ../model/_0081.png
     ../model/_0082.png
     ../model/_0083.png
     ../model/_0084.png
     ../model/_0085.png
     ../model/_0086.png
     ../model/_0087.png
     ../model/_0088.png
     ../model/_0089.png
     ../model/_0090.png
     ../model/_0091.png
     ../model/_0092.png
     ../model/_0093.png
     ../model/_0094.png
     ../model/_0095.png
     ../model/_0096.png
     ../model/_0097.png
     ../model/_0098.png
     ../model/_0099.png
     ../model/_0100.png
     ../model/_0101.png
     ../model/_0102.png
     ../model/_0103.png
     ../model/_0104.png
     ../model/_0105.png
     ../model/_0106.png
     ../model/_0107.png
     ../model/_0108.png
     ../model/_0109.png
     ../model/_0110.png
     ../model/_0111.png
     ../model/_0112.png
     ../model/_0113.png
     ../model/_0114.png
     ../model/_0115.png
     ../model/_0116.png
     ../model/_0117.png
     ../model/_0118.png
     ../model/_0119.png
     ../model/_0120.png
     ../model/_0121.png
     ../model/_0122.png
     ../model/_0123.png
     ../model/_0124.png
     ../model/_0125.png
     ../model/_0126.png
     ../model/_0127.png
     ../model/_0128.png
     ../model/_0129.png
     ../model/_0130.png
     ../model/_0131.png
     ../model/_0132.png
     ../model/_0133.png
     ../model/_0134.png
     ../model/_0135.png
     ../model/_0136.png
     ../model/_0137.png
     ../model/_0138.png
     ../model/_0139.png
     ../model/_0140.png
     ../model/_0141.png
     ../model/_0142.png
     ../model/_0143.png
     ../model/_0144.png
     ../model/_0145.png
     ../model/_0146.png
     ../model/_0147.png
     ../model/_0148.png
     ../model/_0149.png
     ../model/_0150.png
     ../model/_0151.png
     ../model/_0152.png
     ../model/_0153.png
     ../model/_0154.png
     ../model/_0155.png
     ../model/_0156.png
     ../model/_0157.png
     ../model/_0158.png
     ../model/_0159.png
     ../model/_0160.png
     ../model/_0161.png
     ../model/_0162.png
     ../model/_0163.png
     ../model/_0164.png
     ../model/_0165.png
     ../model/_0166.png
     ../model/_0167.png
     ../model/_0168.png
     ../model/_0169.png
     ../model/_0170.png
     ../model/_0171.png
     ../model/_0172.png
     ../model/_0173.png
     ../model/_0174.png
     ../model/_0175.png
     ../model/_0176.png
     ../model/_0177.png
     ../model/_0178.png
     ../model/_0179.png
     ../model/_0180.png
     ../model/_0181.png
     ../model/_0182.png
     ../model/_0183.png
     ../model/_0184.png
     ../model/_0185.png
     ../model/_0186.png
     ../model/_0187.png
     ../model/_0188.png
     ../model/_0189.png
     ../model/_0190.png
     ../model/_0191.png
     ../model/_0192.png
     ../model/_0193.png
     ../model/_0194.png
     ../model/_0195.png
     ../model/_0196.png
     ../model/_0197.png
     ../model/_0198.png
     ../model/_0199.png
     ../model/_0200.png
     ../model/_0201.png
     ../model/_0202.png
     ../model/_0203.png
     ../model/_0204.png
     ../model/_0205.png
     ../model/_0206.png
     ../model/_0207.png
     ../model/_0208.png
     ../model/_0209.png
     ../model/_0210.png
     ../model/_0211.png
     ../model/_0212.png
     ../model/_0213.png
     ../model/_0214.png
     ../model/_0215.png
     ../model/_0216.png
     ../model/_0217.png
     ../model/_0218.png
     ../model/_0219.png
     ../model/_0220.png
     ../model/_0221.png
     ../model/_0222.png
     ../model/_0223.png
     ../model/_0224.png
     ../model/_0225.png
     ../model/_0226.png
     ../model/_0227.png
     ../model/_0228.png
     ../model/_0229.png
     ../model/_0230.png
     ../model/_0231.png
     ../model/_0232.png
     ../model/_0233.png
     ../model/_0234.png
     ../model/_0235.png
     ../model/_0236.png
     ../model/_0237.png
     ../model/_0238.png
     ../model/_0239.png
     ../model/_0240.png
     `;
        return data.split("\n")[index];
      }

      const frameCount = 241;

      const images = [];
      const imageSeq = {
        frame: 1,
      };

      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = files(i);
        images.push(img);
      }

      gsap.to(imageSeq, {
        frame: frameCount - 1,
        snap: "frame",
        ease: `none`,
        scrollTrigger: {
          scrub: 0.15,
          trigger: `#page`,
          start: `top top`,
          end: `800% top`,
          scroller: `#main`,
        },
        onUpdate: render,
      });

      images[1].onload = render;

      function render() {
        scaleImage(images[imageSeq.frame], context);
      }

      function scaleImage(img, ctx) {
        var canvas = ctx.canvas;
        var hRatio = canvas.width / img.width;
        var vRatio = canvas.height / img.height;
        var ratio = Math.max(hRatio, vRatio);
        var centerShift_x = (canvas.width - img.width * ratio) / 2;
        var centerShift_y = (canvas.height - img.height * ratio) / 2;

        // Check if the screen width is less than a certain threshold (e.g., 768px for mobile)
        if (window.innerWidth < 768) {
          // Modify the scale factor for mobile screens
          var mobileScaleFactor = 0.7; // Adjust this value as needed
          var mobileCenterShift_x =
            (canvas.width - img.width * ratio * mobileScaleFactor) / 2;
          var mobileCenterShift_y =
            (canvas.height - img.height * ratio * mobileScaleFactor) / 2 - 90; // Adjust this value to move the canvas upward

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            mobileCenterShift_x,
            mobileCenterShift_y,
            img.width * ratio * mobileScaleFactor,
            img.height * ratio * mobileScaleFactor
          );
        } else {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            centerShift_x,
            centerShift_y,
            img.width * ratio,
            img.height * ratio
          );
        }
      }

      ScrollTrigger.create({
        trigger: "#page>canvas",
        pin: true,
        // markers:true,
        scroller: `#main`,
        start: `top top`,
        end: `1500% top`,
      });

      gsap.to("#mainBgArea", {
        scrollTrigger: {
          trigger: `#mainBgArea`,
          start: `top top`,
          end: `1500% top`,
          pin: true,
          scroller: `#main`,
        },
      });
    },
    navigationToggleHideOnScroll: function () {
      const headerScrollAnim = gsap
        .from(".navigationContainer", {
          yPercent: -100,
          paused: true,
          duration: 0.2,
          ease: "power1.out",
        })
        .progress(1);

      ScrollTrigger.create({
        start: "top 90%",
        end: "+=100 top", // Move back when 100px from the top
        scroller: "#main",
        onToggle: (self) => {
          if (self.isActive) {
            headerScrollAnim.play();
          } else {
            headerScrollAnim.reverse();
          }
        },
      });
    },
    isSafariBrowser: function () {
      var isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf("Apple") > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf("CriOS") == -1 &&
        navigator.userAgent.indexOf("FxiOS") == -1;

      if (isSafari) {
        document.documentElement.classList.add("thisIsSafari");
      }
      const iphone = () => {
        var opera;
        if (typeof window === `undefined` || typeof navigator === `undefined`)
          return false;

        return /iPhone/i.test(
          navigator.userAgent ||
            navigator.vendor ||
            (window.opera && opera.toString() === `[object Opera]`)
        );
      };
      var isIphone = iphone();
      if (isIphone) {
        document.documentElement.classList.add("thisIsSafari");
      }
    },
  },
  computed: {
    isNotService() {
      return (
        this.$route.path !== "/web-crafting" &&
        this.$route.path !== "/branding" &&
        this.$route.path !== "/digital-marketing" &&
        this.$route.path !== "/content-marketing"
      );
    },
    isNotContact() {
      return this.$route.path !== "/contact-us";
    },
    isHowWeWork() {
      return this.$route.path === "/how-we-work";
    },
  },
  mounted() {
    window.addEventListener("load", () => {
      // When the entire page is fully loaded, show Enter button in preloader.
      this.showButton = true;
    });
    document.body.className = "";
    document.getElementById("main").className = "";
    if (this.$route.path === "/contact-us") {
      const mainElement = document.getElementById("main");
      if (mainElement) {
        mainElement.classList.add("hideModel");
      }
    }

    gsap.registerPlugin(ScrollTrigger);

    const locoScroll = new LocomotiveScroll({
      el: document.querySelector("#main"),
      smooth: true,
      smoothMobile: 0,
      smartphone: {
        smooth: !0,
        breakpoint: 767,
      },
      tablet: {
        smooth: !1,
        breakpoint: 1024,
      },
    });
    locoScroll.on("scroll", ScrollTrigger.update);

    ScrollTrigger.scrollerProxy("#main", {
      scrollTop(value) {
        return arguments.length
          ? locoScroll.scrollTo(value, 0, 0)
          : locoScroll.scroll.instance.scroll.y;
      },

      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },

      pinType: document.querySelector("#main").style.transform
        ? "transform"
        : "fixed",
    });
    ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
    ScrollTrigger.refresh();

    router.beforeEach((to, from, next) => {
      next();
      setTimeout(function () {
        ScrollTrigger.refresh();
        setTimeout(function () {
          ScrollTrigger.refresh();
          locoScroll.update();
          locoScroll.scrollTo("top");
        }, 1200);
      }, 800);
    });
    this.particleAnim();
    this.homeBottleAnim();
    this.navigationToggleHideOnScroll();
    this.isSafariBrowser();
  },
};
</script>
