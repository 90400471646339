import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/our-story',
    name: 'ourStory',
    component: () => import('../views/OurStoryView.vue')
  },
  {
    path: '/web-crafting',
    name: 'webCrafting',
    component: () => import('../views/WebCraftingView.vue')
  },
  {
    path: '/digital-marketing',
    name: 'digitalMarketing',
    component: () => import('../views/DigitalMarketingView.vue')
  },
  {
    path: '/branding',
    name: 'branding',
    component: () => import('../views/BrandingView.vue')
  },
  {
    path: '/content-marketing',
    name: 'contentMarketing',
    component: () => import('../views/ContentMarketingView.vue')
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/how-we-work',
    name: 'howWeWork',
    component: () => import('../views/HowWeWorkView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
})

export default router
