<template>
    <div>
      <button
        type="button"
        class="siteMusic"
        v-bind:class="{ isPlaying: playSound }"
        title="Toggle sound"
        v-on:click="toggleSound()"
      >
        <span class="hiddenBtn">Toggle sound</span>
        <span class="pulsingCircle"></span>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: "siteMusicComponent",
    data() {
      return {
        playSound: false,
        audio: null,
      };
    },
    methods: {
      toggleSound() {
        if (!this.audio) {
          // Initialize the audio object on the first click
          this.audio = new Audio("../audio/music.mp3");
          this.audio.loop = true;
          this.audio.play();
        } else {
          // Toggle play/pause on subsequent clicks
          if (this.playSound) {
            this.audio.pause();
          } else {
            this.audio.play();
          }
        }
  
        this.playSound = !this.playSound;
      },
      handleVisibilityChange() {
        // Check if the audio object exists before attempting to pause or play
        if (this.audio) {
          if (document.hidden) {
            this.audio.pause();
          } else {
            this.audio.play();
          }
        }
      },
      handleFirstClick() {
        if (!this.audio) {
          this.toggleSound();
          window.removeEventListener("click", this.handleFirstClick);
        }
      },
    },
    mounted() {
      // Add event listener for page visibility change
      document.addEventListener("visibilitychange", this.handleVisibilityChange);
      // Add global event listener
      window.addEventListener("click", this.handleFirstClick);
    },
    beforeUnmount() {
      // Remove event listener for page visibility change
      document.removeEventListener("visibilitychange", this.handleVisibilityChange);
      // Remove global event listener to avoid memory leaks
      window.removeEventListener("click", this.handleFirstClick);
  
      // Clean up the audio object to avoid memory leaks
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
      }
    },
  };
  </script>
  
  <style lang=""></style>
  