<template lang="">
  <div id="page3" class="fullSizePage mutliColorInfoHovers">
    <div class="mutliColorInfoInnerContainer">
      <h2 class="secMainHeading">We Turn Brands Into Gold By</h2>
      <ul class="headingWithHoverDetails">
        <li
          @mouseover="setOverlayClass('greenActive')"
          @mouseout="resetOverlayClass"
        >
          <router-link to="/" class="blockAnchor">
            <h3>
              Web Crafting
              <span>
                <leftArrow />
              </span>
            </h3>
            <p>
              Crafting emotionally immersive experiences to hook your customers
            </p>
            <br />
          </router-link>
        </li>
        <li
          @mouseover="setOverlayClass('goldenActive')"
          @mouseout="resetOverlayClass"
        >
          <router-link to="/" class="blockAnchor">
            <h3>
              Digital Marketing
              <span>
                <leftArrow />
              </span>
            </h3>
            <p>
              Connecting your brand with the right target audience to catalyze
              your growth
            </p>
            <br />
          </router-link>
        </li>
        <li
          @mouseover="setOverlayClass('blueActive')"
          @mouseout="resetOverlayClass"
        >
          <router-link to="/" class="blockAnchor">
            <h3>
              Content Marketing
              <span>
                <leftArrow />
              </span>
            </h3>
            <p>Creating irresistible stories that make your brand memorable</p>
            <br />
          </router-link>
        </li>
        <li
          @mouseover="setOverlayClass('blackActive')"
          @mouseout="resetOverlayClass"
        >
          <router-link to="/" class="blockAnchor">
            <h3>
              Branding
              <span>
                <leftArrow />
              </span>
            </h3>
            <p>Finding your unique brand voice to outshine the competitors</p>
            <br />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="fullSizePage"></div>
</template>
<script>
import leftArrow from "@/components/snippets/leftArrow.vue";
export default {
  name: "mutliColorsBrands",
  components: {
    leftArrow,
  },
  methods: {
    setOverlayClass(className) {
      document.body.className = className;
    },
    resetOverlayClass() {
      document.body.className = "";
    },
  },
};
</script>
<style lang=""></style>
