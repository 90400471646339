<template lang="">
  <nav class="navigationContainer" v-bind:class="{ active: showMenu }">
    <router-link to="/" class="logoLink">
      <img class="logoImg" src="@/assets/img/newLogo.svg"/>
    </router-link>
    <button class="hamBurgerBtn" v-on:click="toggleMenu()">
      <span></span> <span></span> <span></span>
    </button>
  </nav>
  <div class="menuDrawer" v-bind:class="{ active: showMenu }">
    <div class="elipseContainer">
      <div class="elipse elipse1"></div>
      <div class="elipse elipse2"></div>
      <div class="elipse elipse3"></div>
      <div class="elipse elipse4"></div>
    </div>
    <div class="particlesContainer" id="navParticles"></div>
    <div class="menuDraweMenuContainer">
      <ul class="menuList">
        <li>
          <router-link to="/" class="menuLink"  v-on:click="toggleMenu()"> Home </router-link>
        </li>
        <li>
          <button class="menuLink" data-id="servicesSubMenu" @click="toggleSubMenu('servicesSubMenu')">Services</button>
          <div  :class="{subMenu: true, activeSubMenu: activeSubMenu === 'servicesSubMenu',}">
              <div class="subMenuItem">
                <router-link to="/web-crafting" class="subMenuLink"  v-on:click="toggleMenu()">
                  Web Crafting
                </router-link>
              </div>
              <div class="subMenuItem">
                <router-link to="/digital-marketing" class="subMenuLink"  v-on:click="toggleMenu()">
                  Digital Marketing
                </router-link>
              </div>
              <div class="subMenuItem">
                <router-link to="/branding" class="subMenuLink"  v-on:click="toggleMenu()">
                  Branding
                </router-link>
              </div>
              <div class="subMenuItem">
                <router-link to="/content-marketing" class="subMenuLink"  v-on:click="toggleMenu()">
                  Content Marketing
                </router-link>
              </div>
          </div>
        </li>
        <li>
          <button class="menuLink" data-id="aboutSubMenu" @click="toggleSubMenu('aboutSubMenu')"> About Us</button>
          <div :class="{subMenu: true, activeSubMenu: activeSubMenu === 'aboutSubMenu',}">
            <div class="subMenuItem">
            <router-link to="/our-story" class="subMenuLink"  v-on:click="toggleMenu()">
              Our Story
            </router-link>
          </div>
          <div class="subMenuItem">
            <router-link to="/how-we-work" class="subMenuLink"  v-on:click="toggleMenu()">
              How We Work
            </router-link>
           </div>
          </div>
        </li>
        <li>
          <router-link to="/contact-us" class="menuLink" v-on:click="toggleMenu()">
            Contact Us
          </router-link>
        </li>
      </ul>
      <div class="desktopSubMenuContainer">
        <div :class="{subMenu: true, activeSubMenu: activeSubMenu === 'servicesSubMenu',}">          
          <div class="subMenuItem">
                <router-link to="/web-crafting" class="subMenuLink"  v-on:click="toggleMenu()">
                  Web Crafting
                </router-link>
              </div>
              <div class="subMenuItem">
                <router-link to="/digital-marketing" class="subMenuLink"  v-on:click="toggleMenu()">
                  Digital Marketing
                </router-link>
              </div>
              <div class="subMenuItem">
                <router-link to="/branding" class="subMenuLink"  v-on:click="toggleMenu()">
                  Branding
                </router-link>
              </div>
              <div class="subMenuItem">
                <router-link to="/content-marketing" class="subMenuLink"  v-on:click="toggleMenu()">
                  Content Marketing
                </router-link>
              </div>
        </div>
        <div :class="{subMenu: true, activeSubMenu: activeSubMenu === 'aboutSubMenu',}">
          <div class="subMenuItem">
            <router-link to="/our-story" class="subMenuLink"  v-on:click="toggleMenu()">
              Our Story
            </router-link>
          </div>
          <div class="subMenuItem">
            <router-link to="/how-we-work" class="subMenuLink"  v-on:click="toggleMenu()">
              How We Work
            </router-link>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mainNav",
  data() {
    return {
      showMenu: false,
      activeSubMenu: null,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleSubMenu(subMenuId) {
      if (this.activeSubMenu === subMenuId) {
        this.activeSubMenu = null;
      } else {
        this.activeSubMenu = subMenuId;
      }
    },
    particleAnim: function () {
      const particlesContainer = document.getElementById("navParticles");
      var particleCount = 600; //on desktop screen
      if (window.innerWidth < 768) {
        particleCount = 100; //on mobiles screen
      }

      const particles = [];

      // Create particles
      for (let i = 0; i < particleCount; i++) {
        createParticle();
      }

      function createParticle() {
        const particle = document.createElement("div");
        particle.className = "particle";

        const size = Math.random() * 3 + 3; // Random size between 2px and 4px
        const x = Math.random() * window.innerWidth;
        const y = Math.random() * window.innerHeight;
        const color = getRandomColor();

        particle.style.width = `${size}px`;
        particle.style.height = `${size}px`;
        particle.style.left = `${x}px`;
        particle.style.top = `${y}px`;
        particle.style.background = color;

        particlesContainer.appendChild(particle);

        // Store particles in the array
        particles.push({
          element: particle,
          x,
          y,
          speedY: Math.random() * 0.5 + 0.1, // Slower vertical speed
          speedX: Math.random() * 0.5 + 0.1, // Slower horizontal speed
        });
      }

      // Animation loop
      function animateParticles() {
        for (const particle of particles) {
          // Update particle positions for a parallax effect
          particle.x -= particle.speedX; // Added horizontal motion
          particle.y -= particle.speedY; // Added vertical motion

          // Reset particle position if it goes above the viewport
          if (particle.y < 0) {
            particle.y = window.innerHeight + Math.random() * 100;
            particle.x = Math.random() * window.innerWidth;
          }

          // Apply the new position
          particle.element.style.transform = `translate(${particle.x}px, ${particle.y}px)`;
        }

        requestAnimationFrame(animateParticles);
      }

      // Helper function to generate random colors
      function getRandomColor() {
        const colors = ["#ffffff", "#ebebeb", "#d7d7d7"]; // White, light gray, and dark gray
        return colors[Math.floor(Math.random() * colors.length)];
      }

      // Start the animation loop
      animateParticles();
    },
  },
  mounted: function mounted() {
    this.particleAnim();
  },
};
</script>
<style lang=""></style>
