<template>
  <div class="mainHome">
    <heroTxt />
    <goldSmith />  <!-- contains #page1 and #page2 --> 
    <mutliColorsBrandInfo /> <!-- contains #page3 --> 
    <hallOfGoldMiners /> <!-- contains #page4 and #page5 -->    
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import heroTxt from "@/components/homePage/heroTxt.vue";
import goldSmith from "@/components/homePage/goldSmith.vue";
import mutliColorsBrandInfo from "@/components/homePage/mutliColorsBrandInfo.vue";
import hallOfGoldMiners from "@/components/homePage/hallOfGoldMiners.vue";
export default {
  name: "HomeView",
  components: {
    heroTxt,
    goldSmith,
    mutliColorsBrandInfo,
    hallOfGoldMiners,
  },
  methods: {
    pageAnimations: function () {
      gsap.to("#page1", {
        scrollTrigger: {
          trigger: `#page1`,
          start: `top top`,
          end: `bottom top`,
          pin: false,
          scroller: `#main`,
        },
      });
     
      gsap.to("#page3", {
        scrollTrigger: {
          trigger: `#page3`,
          start: `top top`,
          end: `bottom top`,
          pin: true,
          scroller: `#main`,
        },
      });
    },
    textRevealAnim: function () {
      let reveal = document.querySelectorAll(".reveal");
      reveal.forEach((el) => {
        let headings = el.querySelectorAll("h1, h2, h3, h4, h5 a, p, li");
        let tl = gsap.timeline().from(headings, {
          y: 200,
          stagger: 0.05,
          opacity: 0,
          duration: 2,
          ease: "power3.out",
        });
        ScrollTrigger.create({
          trigger: el,
          start: "center 100%",
          end: "top 50%",
          markers: false,
          toggleActions: "play none none reverse",
          animation: tl,
          scroller: `#main`,
        });
      });
    },

    // goldenBgAnim: function () {
    //   // Function to add the 'goldenActive' class to the body
    //   function addGoldenActiveClass() {
    //     document.body.classList.add("goldenActive");
    //   }

    //   // Function to remove the 'goldenActive' class from the body
    //   function removeGoldenActiveClass() {
    //     document.body.classList.remove("goldenActive");
    //   }
    //   // Animation to fade in #goldBg when #page1 starts
    //   gsap.to("#goldBg", {
    //     opacity: 1, // Set the target opacity to 1
    //     scrollTrigger: {
    //       trigger: "#page1",
    //       endTrigger: "#page2",
    //       start: "top center", // Adjust this according to your needs
    //       end: "top top", // Adjust this according to your needs
    //       scrub: true, // Allows for smooth changes during scroll
    //       markers: false, // Add this to visualize the trigger and animation
    //       scroller: `#main`,
    //       onEnter: addGoldenActiveClass, // Add the class when #goldBg enters
    //       onLeaveBack: removeGoldenActiveClass, // Remove the class when #goldBg fades out
    //     },
    //   });

    //   // Animation to fade out #goldBg when #page1 ends
    //   gsap.to("#goldBg", {
    //     opacity: 0,
    //     scrollTrigger: {
    //       trigger: "#page1",
    //       endTrigger: "#page2",
    //       start: "bottom center", // Adjust this based on your layout
    //       end: "top top", // Adjust this based on your layout
    //       scrub: true,
    //       markers: false,
    //       scroller: `#main`,
    //       onEnter: removeGoldenActiveClass, // Add the class when #goldBg enters
    //       onLeaveBack: addGoldenActiveClass, // Remove the class when #goldBg fades out
    //     },
    //   });
    // },
  },
  mounted: function mounted() {
    document.body.className = "";
    this.pageAnimations();
    this.textRevealAnim();
  },
};
</script>
