<template lang="">
  <div id="mainBgArea" class="backgroundAreaContainer">
    <img class="mainBgImg mainBg" src="@/assets/img/mainBG.png" alt="" />
    <img id="goldBg" class="mainBgImg" src="@/assets/img/goldBg.png" alt="" />
    <img id="greenBg" class="mainBgImg" src="@/assets/img/greenBg.png" alt="" />
    <img id="brownBg" class="mainBgImg" src="@/assets/img/brownBg.png" alt="" />
    <img id="blueBG" class="mainBgImg" src="@/assets/img/blueBG.png" alt="" />
    <img id="blackBg" class="mainBgImg" src="@/assets/img/blackBg.png" alt="" />
    <img class="smokeBg" src="@/assets/img/smoke.png" />
    <div class="particlesContainer" id="particles-container"></div>
  </div>
</template>
<script>
export default {
  name: "homeBodyBgs",
};
</script>
<style lang=""></style>
