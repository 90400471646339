<template lang="">
  <div id="page1" class="fullSizePage simpleFullPageSec">
    <h1>Goldsmiths Just Went Digital</h1>
  </div>
  <div id="page2" class="halfSizePage">

  </div>
</template>
<script>
export default {
    name: "goldSmith"
};
</script>
<style lang=""></style>
