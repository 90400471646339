<template lang="">
  <div class="footerContainer">
    <div class="footerInner">
      <div class="footerTopRow">
        <form ref="form" class="newsLetter" @submit.prevent="sendEmail">
          <label
            v-if="showNewsLetter"
            class="formSubmitLabel"
            :class="{ showSuccess: successMessage, showError: errorMessage }"
          >
            {{ successMessage ? successMessage : errorMessage }}
          </label>
          <div class="formInputAndBtn">
            <input
              type="email"
              required
              name="from_email"
              placeholder="Enter your email address"
            />
            <button class="submitBtn" type="submit">
              <leftArrow /> Sign Up
            </button>
          </div>
        </form>
        <div class="ftSocials">
          <a href="https://www.instagram.com/alchelyst" target="_blank">
            Ins
          </a>
          <span>/</span>
          <a
            href="https://www.linkedin.com/company/alchelystdigital"
            target="_blank"
          >
            Li
          </a>
          <span>/</span>
          <a href="https://www.behance.net/alchelyst" target="_blank"> Be </a>
        </div>
      </div>
      <div class="footerBtmRow">
        <div class="footerLogoInfo">
          <p>Transmuting Brands Into Gold</p>
        </div>
        <div class="footerContactNumber">
          <h4>Canada</h4>
          <p>
            <a href="tel:+1 (604) 704-4324">+1 (604) 704-4324</a>
          </p>
        </div>
        <div class="footerContactNumber">
          <h4>Pakistan</h4>
          <p>
            <a href="tel:+923088461517">+92 308 8461517</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import leftArrow from "@/components/snippets/leftArrow.vue";
import emailjs from "@emailjs/browser";
export default {
  name: "siteFooter",
  data() {
    return {
      successMessage: "",
      errorMessage: "",
    };
  },
  components: {
    leftArrow,
  },
  props: {
    showNewsLetter: {
      type: Boolean,
      default: true, // Set a default value to true
    },
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_assi2xl",
          "template_2jvf0xb",
          this.$refs.form,
          "YOHMStLe36UhgK-W3"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.successMessage =
              "Thank you, You have successfully subscribed to our newsletter.";
            // Clear form fields
            this.clearFormFields();
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.errorMessage =
              "Oops! Something went wrong. Please try again later.";
          }
        );
    },
    clearFormFields() {
      const formFields = this.$refs.form.querySelectorAll("input");
      formFields.forEach((field) => (field.value = ""));
    }
  },
};
</script>
<style lang=""></style>
