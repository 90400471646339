<template lang="">
  <div id="page5" class="extraFullSizePage goldMinersHallsInfo">
    <div class="goldMinersSecInfo">
      <h3 class="secHeading2">The Hall of Goldminers</h3>

      <ul class="headingWithHoverDetails">
        <li>
          <a
            target="_blank"
            href="https://choiceinsuranceservices.ca/"
            class="blockAnchor"
          >
            <h3>
              Choice
              <span>
                <leftArrow />
              </span>
            </h3>
            <p>
              Created a trustworthy and engaging website for Choice, tailored to
              connect and build confidence with their insurance clientele
       
            </p>
            <br />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://zimedpf.ca/" class="blockAnchor">
            <h3>
              Zimed
              <span>
                <leftArrow />
              </span>
            </h3>
            <p>
              Developed an interactive 3D model website for Zimed, featuring
              high-quality animations and engaging design to showcase their
              products uniquely.
            </p>       <br />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.monos.ai/" class="blockAnchor">
            <h3>
              Monos
              <span>
                <leftArrow />
              </span>
            </h3>
            <p>
              Crafted a vibrant and user-friendly Halal directory for Monos,
              enhancing the online Muslim community experience.
            </p>
            <br/>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import leftArrow from "@/components/snippets/leftArrow.vue";
export default {
  name: "mutliColorsBrands",
  components: {
    leftArrow,
  },
};
</script>
<style lang=""></style>
