<template lang="">
  <div class="heroTxtContainer contentContainer reveal">
    <h1 class="commonHeading">We Transmute Brands Into Gold</h1>
    <div class="heroRightArea">
      <h3>
        Alchelyst is a Creative Branding Studio fusing the Influential Powers of
        Design, Culture And Storytelling.
      </h3>
      <p>Our creative masters can catalyze your digital impact.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "heroTxt",
};
</script>
<style lang=""></style>
