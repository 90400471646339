<style scoped>
.typedText {
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  font-family: 'Abril Fatface', serif;
}
@media only screen and (max-width: 900px) {
  .typedText {
    font-size: 2rem;
  }
}
</style>
<template>
  <div class="preloader">
    <div class="loaderContentContainer">
      <span class="animatedLoadingTxt">
        <span class="typedText"></span>
        <span class="typingCursor blinkingAnim">&nbsp;</span>
      </span>
      <button
        v-if="showButton"
        @click="handleEnterClick"
        class="primaryBtn"
        type="button"
      >
        Enter <span v-html="arrowIcon"></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrowIcon: `<svg fill="none" viewBox="0 0 36 26"><path fill="currentColor" fill-rule="evenodd" d="M34.87 14.37a1.937 1.937 0 000-2.74L24.537 1.297a1.938 1.938 0 00-2.74 2.74l7.026 7.026H2.5a1.938 1.938 0 000 3.874h26.323l-7.026 7.026a1.938 1.938 0 002.74 2.74L34.87 14.37z" clip-rule="evenodd"/></svg>`,
    };
  },
  methods: {
    handleEnterClick() {
      this.$emit("enterClick");
    },
    typingAnimation: function () {
      const typedText = document.querySelector(".typedText");
      const typingCursor = document.querySelector(".typingCursor");

      const textArray = [
        "All gold things take time",
        "Mining gold, please wait",
        "Transmuting ordinary to extraordinary",
      ];

      let textArrayIndex = 0;
      let charIndex = 0;

      const eraseAndType = () => {
        erase(() => {
          textArrayIndex = (textArrayIndex + 1) % textArray.length;
          type();
        });
      };

      const erase = (callback) => {
        if (charIndex > 0) {
          typingCursor.classList.remove("blinkingAnim");
          typedText.textContent = textArray[textArrayIndex].slice(
            0,
            charIndex - 1
          );
          charIndex--;
          setTimeout(() => erase(callback), 50); // Increase the erase typing time
        } else {
          typingCursor.classList.add("blinkingAnim");
          setTimeout(() => {
            callback(); // Call the provided callback function
          }, 1000); // Adjust this delay to control the pause timing after erasing
        }
      };

      const type = () => {
        if (charIndex <= textArray[textArrayIndex].length - 1) {
          typingCursor.classList.remove("blinkingAnim");
          typedText.textContent += textArray[textArrayIndex].charAt(charIndex);
          charIndex++;
          setTimeout(type, 50); // Increase the typing time for each character
        } else {
          typingCursor.classList.add("blinkingAnim");
          setTimeout(eraseAndType, 3000); // Start erasing after typing is complete
        }
      };

      document.addEventListener("DOMContentLoaded", () => {
        type(); // Start typing animation
      });
    },
  },
  mounted() {
    this.typingAnimation();
  },
};
</script>
